import { getRequests } from "utils/requests";

const requests = getRequests("brand");

const headers = {
  type: "CRUD",
  options: {
    name: "Marcas",
    route: "/marcas",
    requests,
    tableOptions: {
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
      isSearchable: true,
    },
  },
  sections: [
    {
      fields: [
        { property: "name", label: "Nombre", tableOptions:{isSortable: true, sortProperty: "nameOrder"} },
        //{ property: "url", label: "Url" },
      ],
    },
  ],
};

export default headers;
