import {
  getRequests,
  findRequest,
  findAllRequest,
  findAllRequestOrderName,
  uploadFileRequest,
  getIMPWithSearch,
  mediolibraryrequest
} from "utils/requests";
import { image,dateCreate, imageLibrary } from "utils/fields";
import { DateTime } from "luxon";

const requests = getRequests("innovationMeetingPoint");

const headers = {
  type: "CRUD",
  options: {
    name: "Vanguardia tecnológica",
    route: "/vanguardia-tecnologica",
    requests: {
      ...requests,
      uploadFileRequest,
      findRequest: getIMPWithSearch(),
      mediolibraryrequest: mediolibraryrequest,
      medialibrary: getRequests("medialibrary"),
    },
    tableOptions: {
      isEditable: true,
      isDeletable: true,
      isSearchable: true,
      displayItemsPerPage: false,
    },
    upsertOptions: {
      onMount: () =>
        Promise.all([
          findAllRequestOrderName("category", true)(),
          findRequest("brand", true)(),
        ]).then(([{ data: categoryCompany }, { data: brand }]) => ({
          categoryCompany,
          brand,
        })),
    },
  },
  sections: [
    {
      fields: [
        {
          label: "Fecha de publicación",
          property: "date",
          type: "datetime-local",
          tableOptions: {
            isSortable: true,
            show: true,
            format: (item,value) => DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm")
          },
          upsertOptions: {
            beforeSave: ({ state, field }) => {
              return state?.date
                ? DateTime.fromISO(state?.date)
                    .toUTC()
                    .toFormat("yyyy-MM-dd'T'HH:mm:ss'Z")
                : "";
            },
          },
        },
        { property: "name", label: "Nombre",tableOptions: {isSortable:true, sortProperty:"nameOrder" } },
        imageLibrary({ label: "Imagen", property: "imageUrl" }),
        {
          property: "description",
          label: "Descripción",
          tableOptions: { show: false },
          type: "html",
          value: ({ state }) => state?.data?.description,
          style: {
            flexBasis: "100%",
            zIndex: 0,
          },
          controlProps: {
            init: {
              image_caption: true,
            },
          },
        },
      ],
    },
  ],
};

export default headers;
