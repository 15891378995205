import { findAllRequest, findAllRequestOrderName, getRequests, uploadFileRequest,
mediolibraryrequest } from "utils/requests";
import { image, imageLibrary } from "utils/fields";

const requests = getRequests("magazine");

const options = [
  { value: "TECNIFOOD", label: "Tecnifood" },
  { value: "GTA", label: "Guía de tecnología alimentaria" },
  { value: "PDFNOTICIA", label: "PDF asociado a Noticia" },
  { value: "INFORME", label: "Informe" },
];

const headers = {
  type: "CRUD",
  options: {
    name: "Publicaciones",
    route: { path: "/magazines" },
    requests: {
      ...requests,
      uploadFileRequest,
      mediolibraryrequest: mediolibraryrequest,
      medialibrary: getRequests("medialibrary"),
    },
    tableOptions: {
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
      isSearchable: true,
    },
    upsertOptions: {
      onMount: () =>
        Promise.all([findAllRequestOrderName("category", true)()]).then(
          ([{ data: categoryCompany }]) => ({
            categoryCompany,
          })
        ),
    },
  },
  sections: [
    {
      fields: [
        imageLibrary({}),
        {
          label: "Número",
          property: "number",
          tableOptions:{isSortable: true}
        },
        {
          label: "Título",
          property: "title",
          tableOptions:{isSortable: true, sortableProduct: "titleOrder"}
        },
        {
          label: "Introducción",
          property: "introduction",
          tableOptions:{isSortable: true}
        },
        {
          label: "Tipo",
          property: "magazineType",
          type: "multiselect",
          required: true,
          isClearable: true,
          tableOptions: {
            isSortable: true,
            sortProperty: "magazineType",
            format: (item, type) =>
              options.find((option) => option.value === type)?.label,
          },
          relation: {
            name: "magazineType",
            options,
            isMulti: false,
            sort: true,
            forceSendItem: false,
            
          },
        },
        {
          label: "Categoria",
          property: "categoryCompany",
          type: "multiselect",
          required: true,
          isClearable: true,
          tableOptions: {
            isSortable: true,
            sortProperty: "categoryCompany",
            format: (item, type) =>
              options.find((option) => option.value === type)?.label,
          },
          relation: {
            name: "categoryCompany",
            isMulti: false,
            sort: true,
            forceSendItem: true,
            nameProps: ["name"],
          },
        },
        {
          label: "Fecha",
          property: "date",
          tableOptions:{isSortable: true}
        },
        {
          label: "PDF",
          property: "pdfURL",
          type: "file-pdf",
          tableOptions: {
            show: false,
          },
          upsertOptions: {
            show: true,
            beforeSave: ({ state, field }) => {
              if (state.pdfURL?.name?.length) {
                const blob = new Blob(
                  [state.pdfURL.file ? state.pdfURL.file : state.pdfURL],
                  {
                    type: "application/pdf",
                  }
                );

                const filename = `${(+new Date()).toString(36)}${state.pdfURL?.name || ".pdf"
                  }`;

                uploadFileRequest({
                  file: blob,
                  filename,
                });
                return filename;
              } else {
                return state?.pdfURL;
              }
            },
          },
        },
        {
          label: "PdfURL",
          property: "id",
          type: "label",
          isEditable: false,
          tableOptions: {
            show: false,
          },
          upsertOptions: {
            format: (item) => {
              const filename = item?.state?.data?.pdfURL
                ? item?.state?.data?.pdfURL?.name || item?.state?.data?.pdfURL
                : "";
              return process.env.REACT_APP_API_URL + "pdf/" + filename;
            },
          },
        },
        {
          label: "VisorURL",
          property: "id",
          type: "label",
          isEditable: false,
          tableOptions: {
            show: false,
          },
          upsertOptions: {
            format: (item) => {
              const filename = item?.state?.data?.slug
                ? item?.state?.data?.slug : "";
              return filename == "" ? "": process.env.REACT_APP_FRONT_URL + "visor/" + filename;
            },
          },
        },
        {
          label: "Precio",
          property: "price",
          type: "number",
          tableOptions: { show: false },
        },
        {
          label: "IVA",
          property: "iva",
          type: "number",
          tableOptions: { show: false },
        },
        {
          label: "Activo",
          property: "active",
          type: "checkbox",
          tableOptions: {
            isSortable: "true",
            format: (item) => (item.active ? "Sí" : "No"),
          },
        },
        {
          label: "Privada",
          property: "isPrivate",
          type: "checkbox",
          tableOptions: { isSortable: "true",format: (item, value) => (value ? "Si" : "No") },
        },
      ],
    },
  ],
};
export default headers;
