import { getRequests } from "utils/requests";

const requests = getRequests("suscriptiontype");

/*const options = [
  { value: "DULCESNOTICIAS", label: "Dulces Noticias" },
  { value: "GUIADULCESAPERITIVOS", label: "Guia dulces y aperitivos" },
];*/

const headers = {
  type: "CRUD",
  options: {
    name: "Suscripciones",
    route: "/suscriptiontype",
    requests,
    tableOptions: {
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
    },
  },
  sections: [
    {
      fields: [
        { property: "name", label: "Nombre",tableOptions: {isSortable:true }},
        { property: "price", label: "Precio", type: "number", tableOptions: {isSortable:true } },
        /*{
          property: "allowComplete",
          label: "Es Completa",
          type: "checkbox",
          tableOptions: { format: (item, value) => (value ? "Si" : "No") },
        },
        {
          property: "allowMin",
          label: "Es Mínima",
          type: "checkbox",
          tableOptions: { format: (item, value) => (value ? "Si" : "No") },
        },
        {
          property: "byBonus",
          label: "Tiene Bono",
          type: "checkbox",
          tableOptions: { format: (item, value) => (value ? "Si" : "No") },
        },
        { property: "numBonus", label: "Número de Bonos", type: "number" },*/
        {
          property: "featured",
          label: "Destacada",
          type: "checkbox",
          tableOptions: { isSortable: true, format: (item, value) => (value ? "Si" : "No") },
        },
        {
          property: "sweetPress",
          label: "Acceso a tecnifood",
          type: "checkbox",
          tableOptions: {  isSortable: true, format: (item, value) => (value ? "Si" : "No") },
        },
        {
          property: "sweetsAndSnacks",
          label: "Acceso a tecnología alimentaria",
          type: "checkbox",
          tableOptions: {  isSortable: true, format: (item, value) => (value ? "Si" : "No") },
        },
        {
          property: "privateNews",
          label: "Acceso a noticias privadas",
          type: "checkbox",
          tableOptions: {  isSortable: true, format: (item, value) => (value ? "Si" : "No") },
        },
        {
          label: "Acceso a PDF ASOCIADO A NOTICIAS",
          property: "accessPdfNews",
          type: "checkbox",
          tableOptions: {  isSortable: true, format: (item, value) => (value ? "Si" : "No") },
        },
        {
          label: "Descripción",
          property: "description",
          type: "html",
          value: ({ state }) => state?.data?.description,
          style: {
            flexBasis: "100%",
            zIndex: 0,
          },
          tableOptions: {
            show: false,
          },
          controlProps: {
            init: {
              image_caption: true,
            },
          },
        },
        /*{
          property: "type",
          label: "Tipo de suscripción",
          type: "multiselect",
          tableOptions: {
            format: (item, type) =>
              options.find((option) => option.value === type)?.label,
          },
          relation: {
            name: "type",
            options,
            isMulti: false,
          },
        },*/
      ],
    },
  ],
};

export default headers;
