import { findAllRequest, findAllRequestOrderTitle, findAllRequestOrderName, getRequests, uploadFileRequest, mediolibraryrequest } from "utils/requests";
import { DateTime } from "luxon";
import { image, imageLibrary } from "utils/fields";

const requests = getRequests("ads");

const optionsTranslation = (key) => {
  switch (key) {
    case "inicio":
      return "Portada";
    case "en-directo":
      return "Directo";
    case "noticias":
      return "Noticias";
    case "hemeroteca":
      return "Hemeroteca";
    case "techpress-tv":
      return "Techpress TV";
    case "actualidad":
      return "Actualidad";
    case "guia-tecnologia-alimentaria":
      return "Guía Tecnología alimentaria";
    case "actualidad":
      return "Actualidad";
    case "ultimas-investigaciones":
      return "Últimas investigaciones";
    case "entrevistas":
      return "Entrevistas";
    case "industria-alimentaria":
      return "Industría alimentarios";
    case "food-tech":
      return "Food Tech";
    case "opinion":
      return "Opinión";
    case "reportajes":
      return "Reportajes";
    default:
      return key;
  }
};




const adsTypesOptions = [
  { value: "ADSHOME", label: "Mediabanner home" },
  { value: "ADSSECTION", label: "Mediabanner seccion" },
  { value: "DERCHLAT", label: "Lateral derecho" },
  { value: "IZQLAT", label: "Lateral izquierdo" },
  { value: "MEGABANNER", label: "Megabanner" },
  { value: "MEDIABANNER", label: "Sidebar home" },
  { value: "SIDEBARNEWS", label: "Sidebar noticias" },
  { value: "SIDEBAR", label: "Sidebar sección" },
  { value: "SIDEBARDULCESNOTICIAS", label: "Sidebar Tecnifood" },
  { value: "SIDEBARGUIADULCES", label: "Sidebar Tecnología alimentaria" },
  { value: "ROBAPAGINAS", label: "Robapaginas seccion" },
];


const headers = {
  type: "CRUD",
  options: {
    name: "Anuncios",
    route: { path: "/ads" },
    requests: {
      ...requests,
      uploadFileRequest,
      mediolibraryrequest: mediolibraryrequest,
      medialibrary: getRequests("medialibrary"),
    },
    tableOptions: {
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
      isSearchable: true,
    },
    upsertOptions: {
      onLoadMapping: (data) =>
        Promise.resolve({
          ...data,
          position: data.position.map((item) => ({
            ...item,
            label: optionsTranslation(item.name),
          })),
          type: adsTypesOptions.find(({ value }) => data.type === value),
        }),
      onMount: () =>
        Promise.all([findAllRequestOrderName ("positionads", true)()]).then(
          ([{ data: position }]) => ({
            position: position
              .map((item) => ({
                ...item,
                label: optionsTranslation(item.name),
              }))
              .sort((a, b) =>
                a.label > b.label ? 1 : b.label > a.label ? -1 : 0
              ),
          })
        ),
      itemBeforeSave: (data) => {
        let item = {
          ...data,
        };
        if (data?.image?.size) {
          const blob = new Blob([data.image]);

          return uploadFileRequest({
            file: blob,
          }).then((imageName) => {
            if (data?.imageMovil?.size) {
              const blob = new Blob([data.imageMovil]);
              return uploadFileRequest({
                file: blob,
              }).then((imageMovilName) => {
                return Promise.resolve({
                  ...item,
                  image: imageName,
                  imageMovil: imageMovilName,
                });
              });
            } else {
              return Promise.resolve({ ...item, image: imageName });
            }
          });
        } else if (data.imageMovil?.size) {
          const blob = new Blob([data.imageMovil]);
          return uploadFileRequest({
            file: blob,
          }).then((imageMovilName) => {
            return Promise.resolve({ ...item, imageMovil: imageMovilName });
          });
        } else {
          return Promise.resolve(item);
        }
      },
    },
  },
  sections: [
    {
      fields: [
        {
          label: "Título",
          property: "title",
          tableOptions: {
            isSortable: true
          }
        },
        {
          label: "URL",
          property: "url",
          tableOptions: {
            isSortable: true
          }
        },
        imageLibrary({
          label: "Imagen",
          property: "image",
          showInTable: false,
        }),
        imageLibrary({
          label: "Imagen Móvil",
          property: "imageMovil",
          showInTable: false,
        }),
        {
          label: "Tipo",
          property: "type",
          type: "multiselect",
          isClearable: false,
          tableOptions: {   
            show: true,
            format:(field,state) => {
              var result = "";
              if (state){
                adsTypesOptions.forEach(element => {
                  if (element.value == state){
                    result  =  element.label;
                  }
                });

              } 
              return result;
              
            }
          },
          relation: {
            sort: true,
            // name: "type",
            isMulti: false,
            options: adsTypesOptions,
          },
        },
        {
          label: "Posición",
          property: "position",
          type: "multiselect",
          isClearable: true,
          isCreatable: false,
          tableOptions: { show: false, },
          relation: {
            name: "position",
            isMulti: true,
            sort: true,
            // forceSendItem: true,
            primaryKey: "id",
            nameProps: ["label"],
          },
        },
        {
          label: "Orden",
          property: "order",
          type:"number",
          tableOptions: {
            isSortable: true,
          }
        },
        {
          label: "Fecha de inicio",
          property: "startDate",
          type: "date",
          tableOptions: {
            isSortable: true,
            format: (field, state) => {
              return state
                ? DateTime.fromISO(state).toFormat("dd-MM-yyyy")
                : "";
            },
          },
          upsertOptions: {
            beforeSave: ({ state, field }) => {
              return state?.startDate
                ? DateTime.fromISO(state?.startDate).toFormat(
                    "yyyy-MM-dd'T'00:00:00'Z"
                  )
                : "";
            },
          },
        },
        {
          label: "Fecha de fin",
          property: "endDate",
          type: "date",
          tableOptions: {
            isSortable: true,
            format: (field, state) => {
              return state
                ? DateTime.fromISO(state).toFormat("dd-MM-yyyy")
                : "";
            },
          },
          upsertOptions: {
            beforeSave: ({ state, field }) => {
              return state?.endDate
                ? DateTime.fromISO(state?.endDate).toFormat(
                    "yyyy-MM-dd'T'00:00:00'Z"
                  )
                : "";
            },
          },
        },
        {
          label: "Abrir en la misma pestaña",
          property: "openSamePage",
          tableOptions: { show: false },
          type:"checkbox"
        },
        {
          label: "Html",
          property: "html",
          type: "html",
          value: ({ state }) => state?.data?.html,
          style: {
            flexBasis: "100%",
            zIndex: 0,
          },
          tableOptions: {
            show: false,
          },
          controlProps: {
            init: {
              image_caption: true,
            },
          },
        },
      ],
    },
  ],
};

export default headers;
